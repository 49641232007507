export function modifyServiceDescription(service, apiBaseMedia) {
  return {
    ...service,
    description: service.description.replace(/<img\s+[^>]*src=["']([^"']*)["']/g, (match, p1) => {
      if (!p1.startsWith('http') && !p1.startsWith(apiBaseMedia)) {
        return match.replace(p1, `${apiBaseMedia}${p1}`);
      }
      return match;
    }),
  };
}

export function modifyServicesDescriptions(services, apiBaseMedia) {
  return services.map((service) => modifyServiceDescription(service, apiBaseMedia));
}
