<template>
  <div class="container d-flex flex-row">
    <form
      class="form flex-1 p-6"
      :style="{ '--first-color': firstColor, '--second-color': secondColor }"
      @submit.prevent="register"
    >
      <div class="logo">
        <img
          v-if="logo"
          :src="logo"
          alt="logo"
          class="mt-2"
        >
        <img
          v-else
          src="../assets/images/logo.png"
          alt="logo"
        >
      </div>
      <div
        v-if="registrationError && registrationError.errors"
        class="error-message ml-2"
      >
        <p>Oups ! Un problème est survenu.</p>
        <ul>
          <li
            v-if="registrationError.errors.email"
            class="list-style"
          >
            {{ registrationError . errors . email[0] }}
          </li>
          <li
            v-if="registrationError.errors.password"
            class="list-style"
          >
            Le champ mot de passe doit avoir au moins 8 caractères.
          </li>
        </ul>
      </div>
      <div
        v-if="!specificSpace"
        class="form-group-register ml-2 mt-2 mb-1"
      >
        <label for="company">Société*</label>
        <input
          id="company"
          v-model="company_name"
          class="p-0-5"
          type="text"
          name="company"
          required
        >
      </div>
      <div class="form-group-register ml-2 mt-2 mb-1">
        <label for="firstName">Prénom*</label>
        <input
          id="firstName"
          v-model="firstname"
          class="p-0-5"
          type="text"
          name="firstName"
          required
        >
      </div>
      <div class="form-group-register ml-2 mt-2 mb-1">
        <label for="lastName">Nom*</label>
        <input
          id="lastName"
          v-model="lastname"
          class="p-0-5"
          type="text"
          name="lastName"
          required
        >
      </div>
      <div class="form-group-register ml-2 mt-2 mb-1">
        <label for="email">E-mail*</label>
        <input
          id="email"
          v-model="email"
          class="p-0-5"
          type="email"
          name="email"
          required
        >
      </div>
      <div class="form-group-register ml-2 mt-2 mb-1">
        <label for="phoneNumber">Téléphone*</label>
        <input
          id="phoneNumber"
          v-model="phone"
          class="p-0-5"
          type="text"
          name="phoneNumber"
          placeholder="exemple : 07 01 02 03 04"
          required
        >
      </div>

      <div class="form-group-register ml-2 mt-2 mb-1">
        <label for="password">Mot de passe*</label>
        <input
          id="password"
          v-model="password"
          class="p-0-5"
          type="password"
          name="password"
          required
        ><br>
        <small class="small-text">le mot de passe doit contenir au moins 8 caractères</small>
      </div>
      <div class="form-group-register ml-2 mt-2 mb-1">
        <label for="confirmPassword">Confirmez votre mot de passe*</label>
        <input
          id="confirmPassword"
          v-model="password_confirmation"
          class="p-0-5"
          type="password"
          name="confirmPassword"
          required
        >
      </div>

      <div class="form-group ml-2 mt-2 mb-1 d-flex flex-column align-items-center">
        <div class="d-flex flex-row">
          <input
            id="acceptCondition"
            v-model="newsletter_accepted"
            type="checkbox"
            name="acceptCondition"
            class="mr-1"
          >
          <label
            for="acceptCondition"
            class="clasip-informations"
          >
            En cochant cette case, j'accepte de recevoir des informations de la
            part de la société CLASSIP uniquement.
          </label>
        </div>
        <br>
        <small class="small-text">Le renseignement des champs marqué d'un astérique est
          obligatoire</small>
        <br>
        <small class="small-text">CLASSIP collecte et traite vos données personnelles afin de créer
          votre compte utilisateur et vous faire bénificier des services. Vous
          pouvez prendre connaissance de notre politique de confidentialité pour
          plus d'informations sur les traitements de données que nous réalisons
          et les droits dont vous disposez sur vos données personnelles en
          cliquant <router-link to="/login"><span class="privacy-policy">ici</span></router-link>
        </small>
      </div>

      <button
        class="button border ml-2 mt-1 p-1"
        type="submit"
      >
        Inscription
      </button>
      <div
        class="login ml-2 mt-2"
        :class="specificSpace ? 'login-specific-space': ''"
      >
        <span>Déjà inscrit(e) ? </span>
        <router-link
          to="/login"
          class="button-login p-0-5"
          :class="specificSpace ? 'button-login-specific-space' : ''"
        >
          Connectez-vous
        </router-link>
      </div>
      <div class="decoration" />
    </form>
    <div
      class="main-discover flex-2 d-flex mt-4 justify-content-center"
      :style="{ '--first-color': firstColor, '--second-color': secondColor }"
    >
      <div
        v-if="services.length > 0"
        class="services-container"
      >
        <p class="discover-title">
          Parmi les services mis à votre disposition...
        </p>
        <div
          v-for="(service, index) in services"
          :key="index"
          :class="['services', index === 0 ? 'first-service' : 'second-service mt-1 mb-1']"
          :style="{ zIndex: activeServiceIndex === index ? 10 : 1 }"
          @click="setActiveService(index)"
        >
          <div class="services">
            <div class="container-image">
              <div
                v-if="getType(service) === 'video'"
                class="container-media"
              >
                <video
                  class="video"
                  poster="../assets/images/logo099BlackBackground.png"
                  controls
                >
                  <source
                    :src="getSource(service)"
                    type="video/mp4"
                  >
                </video>
              </div>
              <div
                v-else
                class="container-media"
              >
                <img
                  :src="`${apiBaseMedia}${service.media}`"
                  :alt="service.label"
                >
              </div>
            </div>

            <div class="container-content p-2">
              <div class="container-title">
                {{ service.label }}
              </div>
              <div class="d-flex flex-row">
                <div class="container-description">
                  <p v-html="service.description" />
                </div>
                <div class="container-logo">
                  <img
                    :src="`${apiBaseMedia}${service.logo}`"
                    :alt="service.label"
                    class="h-4"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="discover-title"
      >
        <div
          class="main-discover flex-2 d-flex mt-4 justify-content-center"
          :style="{ '--first-color': firstColor, '--second-color': secondColor }"
        >
          <div
            v-if="services.length > 0"
            class="services-container"
          >
            <p class="discover-title">
              Parmi les services mis à votre disposition...
            </p>
            <div
              v-for="(service, index) in services"
              :key="index"
              :class="['services', index === 0 ? 'first-service' : 'second-service mt-1 mb-1']"
              :style="{ zIndex: activeServiceIndex === index ? 10 : 1 }"
              @click="setActiveService(index)"
            >
              <div class="services">
                <div class="container-image">
                  <div
                    v-if="getType(service) === 'video'"
                    class="container-media"
                  >
                    <video
                      class="video"
                      controls
                    >
                      <source
                        :src="getSource(service)"
                        type="video/mp4"
                      >
                    </video>
                  </div>
                  <div
                    v-else
                    class="container-media"
                  >
                    <img
                      :src="`${apiBaseMedia}${service.media}`"
                      :alt="service.label"
                    >
                  </div>
                </div>

                <div class="container-content p-2">
                  <div class="container-title">
                    {{ service.label }}
                  </div>
                  <div class="d-flex flex-row">
                    <div class="container-description">
                      <p v-html="service.description" />
                    </div>
                    <div class="container-logo">
                      <img
                        :src="`${apiBaseMedia}${service.logo}`"
                        :alt="service.label"
                        class="h-4"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="discover-title"
          >
            <p>
              Connectez-vous et découvrez tous les services mis à votre
              disposition...
            </p>
          </div>
          <div
            v-if="services.length === 0"
            class="dicover-video"
          />
        </div>
      </div>
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import {
  useToast,
} from 'vue-toast-notification';
import FooterComponent from '../components/FooterComponent.vue';
import {
  useAuthStore,
} from '../store/store.js';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { getCompany } from '../services/api.js';

export default {
  components: {
    FooterComponent,
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      newsletter_accepted: false,
      company_name: '',
      company_id: null,
      registrationError: null,
      specificSpace: false,
      logo: null,
      firstColor: null,
      secondColor: null,
      nb_services: 2,
      services: [],
      activeServiceIndex: null,
      apiBaseMedia: process.env.VUE_APP_BASE_MEDIA_URL,
    };
  },
  async mounted() {
    const currentSubDomain = window.location.hostname.split('.')[0];
    if (currentSubDomain !== process.env.VUE_APP_MAIN_SUB_DOMAIN) {
      try {
        const company = (await getCompany({ subdomain: currentSubDomain, nb_services: this.nb_services })).data;
        this.specificSpace = true;
        this.logo = this.apiBaseMedia + company.logo;
        this.firstColor = company.color_1;
        this.secondColor = company.color_2;
        this.services = company.services;
        this.company_name = company.name;
        this.company_id = company.id;
      } catch (error) {
        this.$router.push({ name: 'NotFound' });
      }
    }
  },
  methods: {
    setActiveService(index) {
      this.activeServiceIndex = index;
    },
    getType(service) {
      let type = 'media';
      if (
        service.media === ''
        || service.media === null
        || service.media === undefined
      ) {
        type = 'video';
      }
      return type;
    },
    getSource(service) {
      let url = '';
      if (service.video_1080) {
        url = `${this.apiBaseMedia}${service.video_1080}`;
      } else if (service.video_720) {
        url = `${this.apiBaseMedia}${service.video_720}`;
      } else if (service.video_480) {
        url = `${this.apiBaseMedia}${service.video_480}`;
      } else if (service.video_360) {
        url = `${this.apiBaseMedia}${service.video_360}`;
      }
      return url;
    },
    async register() {
      try {
        const authStore = useAuthStore();
        const response = await authStore.register({
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          password: this.password,
          password_confirmation: this.password_confirmation,
          newsletter_accepted: this.newsletter_accepted,
          company_name: this.company_name,
          company_id: this.company_id,
          device_name: 'WEB',
        }, !this.specificSpace);

        if (response.success) {
          const toast = useToast();
          toast.success('Utilisateur créé avec succès', {
            position: 'top-left',
          });
        }
        if (!this.specificSpace) {
          this.$router.push('/login');
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        this.registrationError = error.response.data;
      }
    },
  },
};
</script>
<style scoped lang="scss">

.services{
  background-color: #fff;
  width: 27rem;
  border-radius: 1rem;
  color: #052f5f;
  position: relative;
  cursor: pointer;

}

.first-service{
  position: relative;
  right: 6rem;
  top: 5rem;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    margin-right: -135px;
    right: 11%;
    top:-82px;
    background-color: var(--first-color);
    z-index: -1;
    }
}

.second-service{
  position: relative;
  bottom: 14rem;
  left: 7rem;
  &::after {
    content: "";
    position: absolute;
    width: 170px;
    height: 170px;
    background-color: var(--second-color);
    z-index: -1;
    left: -130px;
    top: 354px;
    }
}
.container-image{
  height: 100%;
}

.container-title{
  font-size: 24px;
  font-weight: bold;
}

.container-logo img{
  width: 18rem;
  height: 2rem;
}

.video{
  width: 100%;
  border-radius: 1rem 1rem 0 0 ;
}

 .form {
   position: relative;
   background-color: #fff;
   &::before {
     content: "";
     position: absolute;
     background-color: var(--first-color, #052f5f);
     height: 120px;
     top: 0;
     left:0;
     width: 120px;
   }

   &::after {
     content: "";
     position: absolute;
     background-color: var(--second-color, #f8485e);
     height: 85px;
     right:-42px;
     top: 0;
     width: 85px;
   }
 }

 .decoration{
  background-color: var(--second-color, #f8485e);
  bottom: 0;
  position: absolute;
  left: 0px;
  height: 75px;
  width: 75px;
}
 .logo {
  margin: auto;
}

 .form-group-register input[type="text"],
.form-group-register input[type="tel"],
.form-group-register input[type="email"],
.form-group-register input[type="password"] {
  width: 100%;
  max-width: 355px;
  border: none;
  border-bottom: 1px solid #052f5f;
  height:32px;
  font-size: 1em;
}

.form-group-register label {
  display: block;
  font-weight: bold;
  color: #052f5f;
}

.clasip-informations{
  color: #052f5f;
  font-weight: bold;
}

.small-text {
  color: #052f5f;
  font-style: italic;
}

.privacy-policy {
  color: #052f5f;
  text-decoration: underline;
  font-weight: bold;
}

.form-group {
     width: 100%;
     max-width: 355px;
}
.button {
  max-width: 370px;
  width:100%;
  background-color: #fff;
  color: #052f5f;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

button:hover {
  background-color: #052f5f;
  color: #fff;
}

.button-login {
  background-color: #f8485e;
  border: #f8485e;
  line-height: 1.5rem;
  color: #fff;
  font-size: 12px;
  &-specific-space {
    background-color: v-bind(firstColor);
    border: v-bind(firstColor);
  }
}

.login {
  color: #f8485e;
  font-size: 13px;
  &-specific-space {
    color: v-bind(firstColor)
  }
}

.main-discover {
  width:50vw;
  display: flex;
  flex-direction: column;
  align-items:center;

  .discover-title {
    font-size: 32px;
    color: #052f5f;
    font-weight: 700;
    text-align: center;
    max-width: 500px;
    min-width:300px;
  }

  .dicover-video {
    display: flex;
    justify-content: center;
    position: relative;
    width:70%;
    min-height:270px;
    &::before {
      content: "";
      position: absolute;
      width: 270px;
      height: 270px;
      margin-right:-135px;
      right: 50%;
      background-color: #f8485e;
    }
    &::after {
      content: "";
      position: absolute;
      width: 120px;
      height: 120px;
      margin-left:75px;
      left: 50%;
      top: 75px;
      background-color: #052f5f;
    }
  }
}

    .error-message {
        color: red;
    }

    .list-style {
        list-style: inside;
    }
    @media (max-width: 1250px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .first-service,
  .second-service {
    position: static;
    &::before,
    &::after {
      content: none;
    }
  }
}

    @media (max-width: 1100px) {
  .main-discover {
    display: none;
  }

  .container {
    justify-content: center;
  }

  .form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    &::after {
    content: "";
    position: absolute;
    background-color: var(--second-color, #f8485e);
    height: 85px;
    right: 0px;
    top: 0;
    width: 85px;
  }
  }

  .logo{
    width: 40%;
  }

  .form-group-register{
    width: 325px;
  }
  .button {
    width: 325px;
    display: block;
  }
 .login {
    width: 325px;
    display: block;
    margin-bottom: 4rem;
  }

  .decoration{
  height: 50px;
  width: 50px;
}

}
</style>
