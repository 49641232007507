import {
  defineStore,
} from 'pinia';
import {
  resetPassword,
  deleteUser,
  forgotPassword,
  getServiceCategories,
  login,
  register,
  updateProfil,
  requestAccessToService,
  sendFormRequest,
  getUserServices,
  requestCode,
  getUrlForLinkService,
  updatePassword,
  sendSmsCodeToAccessService,
  verifySmsCodeToAccessService,
  sendSmsCodeToVerifyPhone,
  verifySmsCodeToVerifyPhone,
  validateAndAssociateEmployeeNumber,
  updateSkipModalPreference,
  getInfos,
} from '../services/api.js';
import getCookie from '../utils/cookie.js';

const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('token') || '',
    status: '',
    isAuthenticated: !!localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')) || {},
    storedToken: localStorage.getItem('token') || '',
    adminConnectAs: false,
  }),
  getters: {
    authStatus: (state) => state.status,
    isAuth: (state) => state.isAuthenticated,
    getUser: (state) => state.user,
  },
  actions: {
    setToken(token) {
      this.token = token;
      localStorage.setItem('token', token);
    },
    setAdminConnectAs(value) {
      this.adminConnectAs = value;
      localStorage.setItem('adminConnectAs', value);
    },
    setUserPhoneVerifiedAt(verifiedAt) {
      this.user.phone_verified_at = verifiedAt;
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    setUserVerificationStatusAccepted() {
      this.user.verification_status = 'accepted';
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    setUserVerificationNeeded() {
      this.user.verification_needed = true;
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    setHasProfExpressAccount() {
      this.user.has_prof_express_account = true;
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    async login(credentials, genericPortal = false) {
      try {
        const response = await login(credentials);
        const {
          token,
        } = response.data;
        const {
          user,
        } = response.data;
        this.token = token;
        this.storedToken = token;
        this.status = 'login_success';
        this.user = user;
        this.isAuthenticated = true;
        if (!genericPortal) {
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
        }
        document.cookie = `token=${token}; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};`;
        return response;
      } catch (error) {
        console.error('Login error:');
        throw error;
      }
    },
    logout() {
      try {
        this.token = '';
        this.user = {};
        this.status = '';
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('adminConnectAs');
        this.adminConnectAs = false;
        document.cookie = `token=;path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT"`;
      } catch (error) {
        console.error('Logout error:', error);
      }
    },
    async register(userData, genericPortal = false) {
      try {
        const response = await register(userData);
        console.log(response);
        this.status = 'registration_success';
        if (!genericPortal) {
          const { token, user } = response.data;
          console.log(token);
          this.token = token;
          this.storedToken = token;
          this.user = user;
          this.isAuthenticated = true;
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          document.cookie = `token=${token}; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};`;
        }
        return response;
      } catch (error) {
        console.log('Registration error');
        throw error;
      }
    },

    async updateProfil(userData) {
      try {
        const response = await updateProfil(userData);
        this.status = 'update_success';
        this.user = { ...this.user, ...response.data };
        localStorage.setItem('user', JSON.stringify(this.user));
        return response;
      } catch (error) {
        console.error('Update profil error');
        throw error;
      }
    },

    async resetPassword(payload) {
      try {
        const response = await resetPassword(payload);
        const {
          token,
          user,
        } = response.data;

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        this.token = token;
        this.user = user;
        this.isAuthenticated = true;

        return response;
      } catch (error) {
        console.error(
          'Erreur lors de la réinitialisation du mot de passe:',
          error,
        );
        throw error;
      }
    },

    async updatePassword(payload) {
      try {
        const response = await updatePassword(payload);
        return response;
      } catch (error) {
        return error;
      }
    },

    async forgotPassword(payload) {
      try {
        const response = await forgotPassword(payload);
        this.status = 'url sent';
        return response;
      } catch (error) {
        console.error('Forgot password error:', error);

        return error;
      }
    },

    async deleteUser() {
      try {
        const response = await deleteUser();
        this.logout();
        return response;
      } catch (error) {
        return error;
      }
    },

    async getAllServicesByCategories() {
      try {
        const response = await getServiceCategories();
        return response;
      } catch (error) {
        return error;
      }
    },

    async getAllUserServices() {
      try {
        const response = await getUserServices();
        return response;
      } catch (error) {
        return error;
      }
    },

    async sendFormRequest(serviceId, requestBody) {
      try {
        const response = await sendFormRequest(serviceId, requestBody);
        return response;
      } catch (error) {
        console.log('Error during request to service');
        throw error;
      }
    },

    async requestAccessToService(serviceId) {
      try {
        const response = await requestAccessToService(serviceId);
        return response;
      } catch (error) {
        console.log('Error during request to service');
        throw error;
      }
    },

    async requestCode(serviceId) {
      try {
        const response = await requestCode(serviceId);
        return response;
      } catch (error) {
        console.log(`Error during request code to service ${serviceId}`);
        throw error;
      }
    },

    async getUrlForLinkService(serviceId) {
      try {
        const response = await getUrlForLinkService(serviceId);
        return response;
      } catch (error) {
        console.log(`Error during URL generation for service ${serviceId}`);
        throw error;
      }
    },

    async sendSmsToAccessService(serviceId) {
      try {
        const response = await sendSmsCodeToAccessService(serviceId);
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async verifySmsToAccessService(serviceId, payload) {
      try {
        const response = await verifySmsCodeToAccessService(serviceId, payload);
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async sendSmsToVerifyPhone() {
      try {
        const response = await sendSmsCodeToVerifyPhone();
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async verifySmsToVerifyPhone(payload) {
      try {
        const response = await verifySmsCodeToVerifyPhone(payload);
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async validateAndAssociateEmployee(payload) {
      try {
        const response = await validateAndAssociateEmployeeNumber(payload);
        return response;
      } catch (error) {
        console.error('Failed to validate and associate employee number:', error);
        throw error;
      }
    },

    async updateSkipModalPreference(serviceId) {
      try {
        const response = await updateSkipModalPreference(serviceId);
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async checkAuthentication() {
      const currentSubDomain = window.location.hostname.split('.')[0];
      let token = localStorage.getItem('token');
      let user = localStorage.getItem('user');
      if (!token || !user) {
        const cookieToken = getCookie('token');
        if (!cookieToken) {
          this.logout();
          this.isAuthenticated = false;
        } else {
          token = cookieToken;
          user = (await getInfos()).data;
          if (currentSubDomain !== process.env.VUE_APP_MAIN_SUB_DOMAIN) {
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
          }
          this.token = token;
          this.storedToken = token;
          this.status = 'login_success';
          this.user = user;
          this.isAuthenticated = true;
        }
      }

      if (token !== this.token) {
        this.logout();
        this.isAuthenticated = false;
      }
    },
  },
});

export {
  useAuthStore,
};
