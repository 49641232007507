<template>
  <section class="container mx-auto">
    <h1>
      <span class="discover-title">CONDITIONS GENERALES D’UTILISATION</span>
    </h1>

    <p>Mises à jour le 28/02/2022</p>

    <p>&nbsp;</p>

    <p>
      Les présentes conditions générales d'utilisation (dites « <b>CGU</b>
      ») ont pour objet de définir les conditions dans lesquelles CLASSIP met à
      disposition le site <a href="https://099ans.com">https://099ans.com</a> et
      les Services et de définir les conditions d’accès au Site par l’Utilisateur.
    </p>

    <p>
      La simple navigation sur le Site, avant même la création
      d'un Compte, le cas échéant, emporte automatiquement acceptation par
      l'Utilisateur des présentes CGU.
    </p>

    <p>&nbsp;</p>

    <p class="title">
      <b>ARTICLE 1 : Définitions</b>
    </p>

    <p>&nbsp;</p>

    <ul>
      <li>
        <span class="title">• <b>Services</b>: </span> <span>le site </span>
        <a href="https://099ans.com">
          https://099ans.com
        </a>
        <span> est une
          plateforme digitale a destination des Utilisateurs des Clients de CLASSIP
          (CSE, entreprise, association...) proposant des offres de biens et services, classées
          en différents univers (cadeaux de naissance, contenus ludiques, apprentissage
          par le jeu, soutien scolaire en ligne, code le route, sport et bien-être,
          décoration d’intérieur…), les Clients choisissant les univers mis a disposition des Utilisateurs. </span>
      </li>
      <li>
        <span class="title">• <b>Client</b> : </span> <span>personne morale ou physique (CSE, entreprise, association...)
          qui contracte avec CLASSIP pour avoir recours aux Services pour le compte
          d'Utilisateurs qu'elle désignera. Cette personne morale ou physique doit avoir
          la capacité de contracter un tel engagement. </span>
      </li>
      <li>
        <span class="title">• <b>Utilisateur </b>: </span> <span>toutes personnes bénéficiaires des Services et qui auront été désignées
          par le Client. CLASSIP fournira au Client les informations permettant aux Utilisateurs d'accéder aux
          Services. </span>
      </li>
      <li>
        <span class="title">• <b>Compte</b> : </span> <span>CLASSIP attribuera à chaque utilisateur un compte.
          Ce compte permet aux utilisateurs de bénéficier des Services, de gérer
          et d'accéder aux différentes informations relatives à son compte. </span>
      </li>
      <li>
        <span class="title">• <b>Partenaire</b>: </span> <span>professionnel dont les services sont référencés sur le Site. </span>
      </li>
    </ul>

    <p>&nbsp;</p>

    <p class="title">
      <b>ARTICLE 2 : Accès au site et modalités de fonctionnement</b>
    </p>

    <p>&nbsp;</p>

    <p>
      Le Site permet à l'Utilisateur d’accéder aux Services. Le Site
      est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à
      Internet. Tous les frais supportés par l'Utilisateur pour accéder au service
      (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge. CLASSIP
      décline toute responsabilité dans l'hypothèse de l'inadéquation du système
      d'exploitation de l'ordinateur du Client et/ou de l'Utilisateur avec l'ensemble
      des fonctionnalités du Site.
    </p>

    <p>
      CLASSIP n'est pas responsable de la fourniture de services
      d'accès à Internet et notamment de toute interruption d'accès empêchant le
      recours à ses Services.
    </p>

    <p>&nbsp;</p>

    <p class="title">
      <b>2.1. Création du Compte</b>
    </p>

    <p>&nbsp;</p>

    <p>
      Le Site permet aux Utilisateurs d’accéder aux différents
      Services disponibles sous réserve d’avoir au préalable, créé un Compte. Pour
      créer un Compte, l’Utilisateur doit remplir l’ensemble des champs obligatoires
      figurant sur le formulaire d’inscription. L’Utilisateur doit également avoir lu
      et accepter les présentes CGU ainsi que la Politique de confidentialité.
    </p>

    <p>
      A l’occasion de la création du Compte, l’Utilisateur
      s’engage à fournir des informations personnelles exactes et conformes à la
      réalité et à les mettre à jour, par l’intermédiaire de son profil ou en en
      avertissant CLASSIP, afin d’en garantir la pertinence et l’exactitude tout au
      long de l’utilisation des Services.
    </p>

    <p>
      L’Utilisateur est parfaitement informé que pour la
      vérification de ses coordonnées, un contact téléphonique peut être réalisé par CLASSIP
      ou un de ses prestataires afin de vérifier l’existence et la réalité de son
      numéro de téléphone.
    </p>

    <p>
      Une fois son Compte créé, l’Utilisateur peut retrouver et
      gérer les informations le concernant depuis son profil, accessible à partir du
      bouton prévu à cet effet.
    </p>

    <p>&nbsp;</p>

    <p>
      L’Utilisateur s’engage à garder secret le mot de passe
      choisi lors de la création du Compte et à ne le communiquer à personne. En cas
      de perte ou divulgation de votre mot de passe, l’Utilisateur s’engage à en
      informer sans délai CLASSIP ou à le modifier via son profil. L’Utilisateur est
      seul responsable de l’utilisation faite de son Compte par un tiers, tant qu’il n’a
      pas expressément notifié CLASSIP de la perte, l’utilisation frauduleuse par un
      tiers ou la divulgation de son mot de passe à un tiers.
    </p>

    <p>
      L’Utilisateur s’engage à ne pas créer ou utiliser, sous sa
      propre identité ou celle d’un tiers, d’autres Comptes que celui initialement
      créé.
    </p>

    <p>&nbsp;</p>

    <p class="title">
      <b>2.2. Fonctionnement et disponibilité du Site</b>
    </p>

    <p>&nbsp;</p>

    <p>
      CLASSIP s’efforcera, dans la mesure du possible, de
      maintenir le Site accessible 7 jours sur 7 et 24 heures sur 24. Néanmoins,
      l’accès au Site pourra être temporairement suspendu, sans préavis, en raison
      d’opérations techniques de maintenance, de migration, de mises à jour ou en
      raison de pannes ou de contraintes liées au fonctionnement des réseaux.
    </p>

    <p>
      En outre, CLASSIP se réserve le droit de modifier ou
      d’interrompre, à sa seule discrétion, de manière temporaire ou permanente, tout
      ou partie de l’accès au Site ou de ses fonctionnalités.
    </p>

    <p>&nbsp;</p>

    <p class="title">
      <b>2.3. Newsletter et informations commerciales</b>
    </p>

    <p>&nbsp;</p>

    <p>
      L’Utilisateur est informé que si, au moment de la création
      du Compte, il consent à recevoir les newsletters de la part de CLASSIP en
      cochant la case correspondante, il sera destinataire de la newsletter liée au
      Site. À tout moment, l’Utilisateur pourra cesser de recevoir ces informations via
      la procédure de désinscription proposée en fin de chaque envoi, ou en envoyant
      une demande de désinscription par courrier électronique à&nbsp;conctact@de0a99ans.com.
    </p>

    <p>&nbsp;</p>

    <p class="title">
      <b>2.4. Suppression du Compte</b>
    </p>

    <p>&nbsp;</p>

    <p>
      L'Utilisateur peut à tout moment fermer son Compte via son
      profil ou en faisant une demande en ce sens à l’adresse électronique
      suivante&nbsp;: contact@de0a99ans.com.
    </p>

    <p>
      CLASSIP procèdera dans les meilleurs délais à la
      désactivation du Compte et adressera à l'Utilisateur un courrier électronique
      lui confirmant la clôture et la suppression définitive de l'ensemble de ses données
      du Site.
    </p>

    <p>&nbsp;</p>

    <p class="title">
      <b>ARTICLE 3 : Données personnelles et cookies</b>
    </p>

    <p>&nbsp;</p>

    <p>
      Le Site assure à au Client et à l'Utilisateur une collecte
      et un traitement des données personnelles dans le respect de la vie privée
      conformément au Règlement UE sur la protection des données et la loi n°78-17 du
      6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Pour en
      savoir plus sur le traitement de vos données personnelles, veuillez consulter
      la politique de confidentialité et de cookies.
    </p>

    <p>&nbsp;</p>

    <p>
      En vertu de la règlementation applicable, l'Utilisateur
      dispose d'un droit d'accès, de rectification, de suppression et d'opposition de
      ses données personnelles. L'Utilisateur peut exercer ces droits :
    </p>

    <ul>
      <li><p>• par courrier électronique à l'adresse: contact@de0a99ans.com</p></li>
      <li>
        <p>• par voie postale à l’adresse : </p>
        <p>CLASSIP</p>
        <p>Immeuble EUROPA, Bâtiment A</p>
        <p>310 avenue de l’Europe</p>
        <p>44240 SUCÉ SUR ERDRE</p>
      </li>
    </ul>

    <p>&nbsp;</p>

    <p class="title">
      <b>ARTICLE 4 : Propriété intellectuelle</b>
    </p>

    <p>&nbsp;</p>

    <p>
      Le contenu du Site (et notamment les informations, marques,
      graphismes, vidéos, etc.) ainsi que tous logiciels nécessaires à son
      utilisation, sont protégés par le Code de la Propriété Intellectuelle ainsi que
      toutes normes internationales applicables, et constituent, sauf indications
      contraires, la propriété exclusive de CLASSIP.
    </p>

    <p>&nbsp;</p>

    <p>
      CLASSIP ne concède au Client et à l'Utilisateur aucune licence ni aucun autre droit que celui de consulter le
      site aux fins susvisées.
    </p>

    <p>&nbsp;</p>

    <p>En conséquence, le Client et l'Utilisateur s'interdisent notamment :</p>

    <p>&nbsp;</p>

    <ul>
      <li>
        <p>• De diffuser le contenu du Site par quelque moyen que ce soit ;</p>
      </li>
      <li>
        <p>
          • De le télécharger autrement que dans les cas autorisés
          par CLASSIP et, plus généralement, de le fixer et reproduire sur tout support
          quel qu'il soit, par quelque procédé que ce soit ;
        </p>
      </li>
      <li>
        <p>
          • Plus généralement d'exploiter et/ou d'utiliser tout ou
          une partie des éléments du site par quelque moyen que ce soit et sous quelque
          forme que ce soit à des fins autres que celles expressément autorisées.
        </p>
      </li>
    </ul>

    <p>&nbsp;</p>
  </section>

  <FooterComponent />
</template>

<script>
import FooterComponent from '../components/FooterComponent.vue';

export default {
  name: 'CGU',
  components: { FooterComponent },
};
</script>

<style scoped>
.container {
  width: 75%;
  margin-top: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: #052f5f;
  font-weight: bold;
  font-size: 16px;
}

.discover-title {
  font-size: 32px;
  color: #052f5f;
  font-weight: 700;
  text-align: center;
  max-width: 500px;
  min-width:300px;
}

p {
  color: #052f5f;
}

span {
  color: #052f5f;
}

a {
  color: #2DBEFF;
}
</style>
