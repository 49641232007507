import { createRouter, createWebHistory } from 'vue-router';
import ServicesPage from '../pages/ServicesPage.vue';
import LoginPage from '../pages/LoginPage.vue';
import RegisterPage from '../pages/RegisterPage.vue';
import ResetPasswordPage from '../pages/ResetPasswordPage.vue';
import DashboardPage from '../pages/DashboardPage.vue';
import Profil from '../pages/ProfilPage.vue';
import Security from '../pages/SecurityPage.vue';
import ForgotPasswordPage from '../pages/ForgotPasswordPage.vue';
import authMiddleware from '../middlewares/authMiddleware.js';
import specificSpaceMiddleware from '../middlewares/specificSpaceMiddleware.js';
import NotFound from '../pages/NotFound.vue';
import CGU from '../pages/CGU.vue';
import PrivacyPolicy from '../pages/privacy-policy.vue';
import CookiesPolicy from '../pages/cookies-policy.vue';
import { useAuthStore } from '../store/store.js';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardPage,
    meta: {
      auth: true,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil,
    meta: {
      auth: true,
      validatedAccountRequired: false,
    },
  },

  {
    path: '/connect-as',
    name: 'connect-as',
    beforeEnter: (to, from, next) => {
      const { token } = to.query;
      if (token) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('adminConnectAs');
        document.cookie = `token=;path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT"`;
        const authStore = useAuthStore();
        authStore.setToken(token);
        authStore.setAdminConnectAs(true);
        document.cookie = `token=${token}; path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};`;
        next('/');
      } else {
        next('/');
      }
    },
  },
  {
    path: '/security',
    name: 'ChangePassword',
    component: Security,
    meta: {
      auth: true,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/my-services',
    name: 'Services',
    component: ServicesPage,
    meta: {
      auth: true,
      validatedAccountRequired: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/conditions-generales-utilisation',
    name: 'CGU',
    component: CGU,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/politique-de-confidentialite',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/politique-de-cookies',
    name: 'CookiesPolicy',
    component: CookiesPolicy,
    meta: {
      auth: false,
      validatedAccountRequired: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await authMiddleware(to, from, next);
  specificSpaceMiddleware(next);
});

export default router;
