<template>
  <HeaderComponent />
  <BannerComponent>
    <div class="text-wrapper flex-1 mr-4">
      <strong :style="{ '--first-color': firstColor }">Mes services</strong>
    </div>
  </BannerComponent>
  <div
    v-if="!isMobileSize"
    class="categories-my-services"
  >
    <CategoryButton
      v-for="category in categories"
      :key="category.id"
      :category="category"
      :is-selected="currentCategoryId === category.id"
      @select="setCurrentCategoryId"
    />
  </div>
  <div
    v-else
    class="categories-dropdown"
  >
    <div class="select-container">
      <CustomDropdown
        is
        v-model="currentCategoryId"
        :options="categories"
        :value="currentCategoryId"
        @update:value="setCurrentCategoryId"
      />
    </div>
  </div>
  <div
    v-if="!isMobileSize"
    class="services-my-services mt-1 mb-1"
  >
    <FilterButton
      v-for="service in filteredServices"
      :key="service.id"
      :service="service"
      :is-selected="currentServiceId === service.id"
      @select="setCurrentServiceId"
    />
  </div>
  <div
    v-else
    class="services-dropdown mb-1 mt-1"
  >
    <div class="select-container">
      <CustomDropdown
        is
        v-model="currentServiceId"
        :options="filteredServices"
        :value="serviceByDefault"
        @update:value="setCurrentServiceId"
      />
    </div>
  </div>
  <div>
    <CarouselComponent
      :services="services"
      :selected-service="currentServiceId"
      @require-service-refresh="handleServiceRefresh"
    />
  </div>

  <FooterComponent />
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import { useAuthStore } from '../store/store.js';
import CategoryButton from '../components/Services/CategoryButton.vue';
import FilterButton from '../components/Services/FilterButton.vue';
import CarouselComponent from '../components/CarouselComponent.vue';
import BannerComponent from '../components/BannerComponent.vue';
import CustomDropdown from '../components/CustomDropDown.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    CategoryButton,
    FilterButton,
    CarouselComponent,
    BannerComponent,
    CustomDropdown,
  },
  data() {
    return {
      categories: [],
      services: [],
      filteredServices: [],
      currentCategoryId: null,
      currentServiceId: null,
      serviceByDefault: null,
      isMobileSize: false,
    };
  },
  mounted() {
    const authStore = useAuthStore();
    this.loadServicesAndUserAccess();
    if (
      authStore.getUser
      && authStore.getUser.company
      && authStore.getUser.company.name
    ) {
      this.firstColor = authStore.getUser.company.color_1;
      this.secondColor = authStore.getUser.company.color_2;
    }
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView);
  },
  methods: {
    async loadServicesAndUserAccess() {
      try {
        const authStore = useAuthStore();

        const allServicesResponse = await authStore.getAllServicesByCategories();

        let allServices = allServicesResponse.data.flatMap((category) => category.service.map((service) => ({
          ...service,
          category_id: category.id,
          user_has_access: false,
        })));

        const userServicesResponse = await authStore.getAllUserServices();
        const userServices = userServicesResponse.data;

        allServices = allServices.map((service) => {
          const userHasAccess = userServices.some(
            (userService) => userService.id === service.id,
          );
          return { ...service, user_has_access: userHasAccess };
        });

        const accessibleServices = allServices.filter(
          (service) => service.user_has_access,
        );

        const accessibleCategoryIds = [
          ...new Set(accessibleServices.map((service) => service.category_id)),
        ];

        this.categories = allServicesResponse.data
          .filter((category) => accessibleCategoryIds.includes(category.id))
          .map((category) => ({
            id: category.id,
            label: category.label,
          }));

        this.services = accessibleServices;

        if (this.categories.length > 0) {
          this.setCurrentCategoryId(this.categories[0].id);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    getServicesByCurrentCategory() {
      this.filteredServices = this.services.filter(
        (service) => service.category_id === this.currentCategoryId,
      );
      this.serviceByDefault = this.filteredServices[0].id;
    },
    setCurrentCategoryId(categoryId) {
      this.currentCategoryId = categoryId;
      this.getServicesByCurrentCategory();
    },
    setCurrentServiceId(serviceId) {
      this.serviceByDefault = serviceId;
      this.currentServiceId = serviceId;
    },
    async handleServiceRefresh() {
      try {
        await this.loadServicesAndUserAccess();
      } catch (error) {
        console.error('Failed to refresh services', error);
      }
    },
    checkMobileView() {
      this.isMobileSize = window.innerWidth < 1000;
    },
  },
};
</script>

<style scoped lang="scss">
.text-wrapper {
  color: #052f5f;
  font-size: 42px;
  margin-top: 1rem;
}

strong::before {
  content: "";
  height: 0.2em;
  width: 16rem;
  background: var(--first-color, #052f5f);
  border-radius: 10px;
  position: absolute;
  margin-top: 4rem;
}
.categories-my-services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.services-my-services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 1060px) {
  .categories-my-services {
    flex-wrap: wrap;
  }

  .services-my-services {
    flex-wrap: wrap;
  }

  .text-wrapper {
    margin-right: 0;
    text-align: center;
  }
}

.categories-dropdown,
.services-dropdown {
  display: flex;
  justify-content: center;
}

.select-container {
  position: relative;
}
</style>
