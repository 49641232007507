<template>
  <teleport to="body">
    <div
      class="modal-overlay"
      @click.self="closeModal"
    >
      <div class="modal-content-container">
        <div class="modal-title">
          Vérification de l'accès via SMS
        </div>
        <div class="modal-content">
          <p>Un SMS a été envoyé sur votre numéro</p>
          <input
            v-model="userInputCode"
            type="text"
            placeholder="Entrez le code SMS"
          >
        </div>
        <div class="modal-actions">
          <button
            class="button-home-redirection p-1"
            @click="verifyCode"
          >
            Vérifier
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useAuthStore } from '../../../store/store.js';

export default {
  props: {
    serviceId: {
      type: Number,
      required: true,
    },
  },
  emits: ['verification-sms-success', 'close-verification-service-modal'],
  data() {
    return {
      userInputCode: '',
    };
  },
  methods: {
    async verifyCode() {
      try {
        const useAuth = useAuthStore();
        const payload = {
          code: this.userInputCode,
        };
        const response = await useAuth.verifySmsToAccessService(this.serviceId, payload);
        if (response.data.success) {
          this.$emit('verification-sms-success');
          this.closeModal();
        } else {
          this.errorCode = 'Code non valide.';
        }
      } catch (error) {
        this.errorCode = 'Code non valide.';
      }
    },
    closeModal() {
      this.$emit('close-verification-service-modal');
    },
  },
};
</script>
