import axios from 'axios';
import * as Sentry from '@sentry/vue';
import getCookie from '../utils/cookie.js';
import { redirectToLogin } from '../utils/redirect.js';

const baseURL = process.env.VUE_APP_API_BASE_URL;
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getCookie('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => error,
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      document.cookie = `token=;path=/; domain=${process.env.VUE_APP_BASE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT"`;
      redirectToLogin();
    } else {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

export const login = async (credentials) => {
  try {
    const response = await api.post('/api/v2/login', credentials);
    return response.data;
  } catch (error) {
    console.log('Login error');
    throw error;
  }
};

export const loginProfExpress = async (credentials) => {
  try {
    const response = await api.post('/api/v2/profexpress-login', credentials);
    return response.data;
  } catch (error) {
    console.log('Login error');
    throw error;
  }
};
export const register = async (userData) => {
  try {
    const response = await api.post('/api/v2/register', userData);
    return response.data;
  } catch (error) {
    console.log('Registration error');
    throw error;
  }
};

export const updateProfil = async (userData) => {
  try {
    const response = await api.put('/api/v2/user/profile', userData);
    return response.data;
  } catch (error) {
    console.log('Update profil error');
    throw error;
  }
};

export const updatePassword = async (payload) => {
  try {
    const response = await api.put('/api/v2/user/password', payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (body) => {
  try {
    const response = await api.post('api/v2/reset-password', body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await api.post('/api/v2/forgot-password', email);
    return response.data;
  } catch (error) {
    console.error('Forgot password error:', error);
    return error;
  }
};

export const deleteUser = async () => {
  try {
    const response = await api.put('/api/v2/user/delete');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getServiceCategories = async () => {
  try {
    const response = await api.get('/api/v2/company/categories');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUserServices = async () => {
  try {
    const response = await api.get('/api/v2/user/services');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendFormRequest = async (serviceId, requestBody) => {
  let response;
  try {
    response = await api.post(`/api/v2/service/${serviceId}/request`, requestBody);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const requestAccessToService = async (serviceId) => {
  let response;
  try {
    response = await api.post(`/api/v2/service/${serviceId}/request-access`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const requestCode = async (serviceId) => {
  let response;
  try {
    response = await api.post(`/api/v2/service/${serviceId}/request-code`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getUrlForLinkService = async (serviceId) => {
  let response;
  try {
    response = await api.get(`/api/v2/service/${serviceId}/url`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const sendSmsCodeToAccessService = async (serviceId) => {
  let response;
  try {
    response = await api.get(`/api/v2/service/${serviceId}/send-sms`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const verifySmsCodeToAccessService = async (serviceId, payload) => {
  let response;
  try {
    response = await api.post(`/api/v2/service/${serviceId}/verification-sms`, payload);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const sendSmsCodeToVerifyPhone = async () => {
  let response;
  try {
    response = await api.get('/api/v2/user/send-sms');
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const verifySmsCodeToVerifyPhone = async (payload) => {
  let response;
  try {
    response = await api.post('/api/v2/user/verification-sms', payload);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const validateAndAssociateEmployeeNumber = async (payload) => {
  let response;
  try {
    response = await api.post('/api/v2/user/validate-and-associate-employee', payload);
  } catch (error) {
    console.error('Failed to validate and associate employee number:', error);
    throw error;
  }
  return response;
};

export const updateSkipModalPreference = async (serviceId) => {
  let response;
  try {
    response = await api.put(`/api/v2/service/${serviceId}/update-preference`);
  } catch (error) {
    console.log(error);
    throw error;
  }
  return response;
};

export const sendMessageToSupport = async (message) => {
  try {
    const response = await api.post('api/v2/support/inquiry', message);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getInfos = async () => {
  try {
    const response = await api.get('/api/v2/user/infos');
    return response.data;
  } catch (error) {
    console.log('Get user infos error');
    throw error;
  }
};

export const getCompany = async (params) => {
  try {
    const response = await api.get('/api/v2/company', { params });
    return response.data;
  } catch (error) {
    console.log('Get user infos error');
    throw error;
  }
};
