<template>
  <NotificationBanner />
  <header class="header d-flex flex-row align-items-center">
    <BrandLogo
      class="logo pl-9"
      :first-color="firstColor"
      :second-color="secondColor"
    />

    <div class="navbar">
      <ul class="d-flex flex-row">
        <li>
          <router-link
            to="/"
            class="navbarElements border p-1"
          >
            Accueil
          </router-link>
        </li>
        <li
          v-if="
            user &&
              (user.verification_status === 'accepted' ||
                !user.company?.employees_number_required)
          "
        >
          <router-link
            to="/my-services"
            class="navbarElements border p-1"
          >
            Mes Services
          </router-link>
        </li>
        <li
          v-if="isAuthenticated"
        >
          <a
            class="navbarElements border p-1 drop-down"
            :class="{ 'dropdown-open': showDropdown }"
            @click="toggleDropdown"
          >
            <span class="user-name">{{ user.firstname }} {{ user.lastname }}</span> <i class="fa-regular fa-user" />

          </a>
          <ul
            v-if="showDropdown"
            class="dropdown"
          >
            <router-link to="/profil">
              <li class="dropdown-elements special-li">
                Profil
              </li>
            </router-link>

            <li
              class="dropdown-elements special-li "
              @click="logout"
            >
              Déconnexion
            </li>
          </ul>
        </li>
        <li v-else>
          <router-link
            to="/login"
            class="navbarElements border p-1 ml-2"
          >
            Connexion
          </router-link>
        </li>
      </ul>
      <IconContact v-if="isAuthenticated && isSupportEnabled" />
    </div>
  </header>
</template>

<script>
import { useAuthStore } from '../store/store.js';
import IconContact from './Services/IconContact.vue';
import BrandLogo from './atoms/BrandLogo.vue';
import NotificationBanner from './Utils/NotificationBanner.vue';

export default {
  components: {
    NotificationBanner,
    BrandLogo,
    IconContact,
  },
  data() {
    return {
      showDropdown: false,
      firstColor: null,
      secondColor: null,
    };
  },
  computed: {
    isAuthenticated() {
      return useAuthStore().isAuthenticated;
    },
    user() {
      return useAuthStore().user;
    },
    isSupportEnabled() {
      return process.env.VUE_APP_FREESCOUT_ENABLE_SUPPORT === 'true';
    },
  },
  mounted() {
    const authStore = useAuthStore();
    if (authStore.getUser && authStore.getUser.company && authStore.getUser.company.name) {
      this.firstColor = authStore.getUser.company.color_1;
      this.secondColor = authStore.getUser.company.color_2;
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    logout() {
      useAuthStore().logout();
      this.$router.push('/login');
    },

  },

};
</script>
<style scoped lang="scss">

.navbarElements {
  font-weight: 37.5rem;
  color: #052f5f;
  font-size: 1.1rem;
  font-weight: bold;
}

.navbarElements:hover,
.dropdown-open {
  cursor: pointer;
  background-color: #052f5f;
  color: #fff;
}

.navbar {
  flex: 2;
  ul {
    justify-content: center;

    li:not(.special-li) {
      margin-left: 2rem;

    }
  }
}

.header {
  height: 9rem;
  width: 100%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
}

.user-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  max-width: 100px;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 1.2rem;
  z-index: 1;
  width: 154px;
}

.dropdown li {
  padding: 10px 10px;
  cursor: pointer;
  color: #052f5f;
}

@media screen and (max-width: 900px) {
  .logo {
    padding-left : 0rem
  }

  .navbar ul li:not(.special-li)  {
    justify-content: flex-end;
    padding: 0rem;
  }
}

@media screen and (max-width: 764px) {
  .logo{
    width: 15rem;
  }

  .navbar ul
  {
    flex-wrap: wrap;
}
.navbarElements{
  display: table-cell;
}

.dropdown{
  margin-top: 0.2rem;
}
}

@media screen and (max-width: 576px) {

  .header{
    display: flex;
    flex-direction: column;
    height: 10rem;
  }

  .navbar ul {
    flex-direction: row;
}
.navbarElements{
  display: table-cell;
}
}
.special-li {
padding-top: 1rem;
transition: background-color 0.3s ease;
}
.special-li:hover {
background-color: rgb(222, 222, 222);
}
</style>
