<template>
  <swiper
    :effect="'coverflow'"
    :space-between="140"
    :centered-slides="true"
    :slides-per-view="'auto'"
    :navigation="true"
    :loop="shouldLoop"
    class="carousel mt-4"
    :modules="modules"
    @swiper="onSwiper"
  >
    <swiper-slide
      v-for="service in computedServices"
      :id="`#service-${service.id}`"
      :key="service.id"
      class="slideRef"
    >
      <div class="container-home">
        <div
          v-if="getType(service) === 'video'"
          class="container-media"
        >
          <video
            class="video"
            poster="../assets/images/logo099BlackBackground.png"
            controls
          >
            <source
              :src="getSource(service)"
              type="video/mp4"
            >
          </video>
        </div>
        <div
          v-else
          class="container-media"
        >
          <img
            :src="`${apiBaseMedia}${service.media}`"
            :alt="service.label"
          >
        </div>
        <div class="container-content p-2">
          <div class="container-header">
            <h1 class="title">
              {{ service.label }}
            </h1>
            <div>
              <img
                :src="`${apiBaseMedia}${service.logo}`"
                :alt="service.label"
                class="logo-service-slider"
              >
            </div>
          </div>
          <div class="d-flex">
            <p
              class="text"
              v-html="service.description"
            />
          </div>
          <div class="container-bottom">
            <div class="flex-2">
              <p
                class="more-informations"
                @click="toggleDetails(service.id)"
              >
                <i class="fa-solid fa-circle-plus" /> Plus d'informations
              </p>
              <p
                v-if="expandedServiceId === service.id"
                class="more-informations-text"
                v-html="service.more_information"
              />
            </div>
            <div
              v-if="service.status === 'INACTIVE'"
              v-html="service.inactive_status_message ?? 'Service inactif'"
            />
            <div
              v-else-if="
                service.status === 'MUST_REQUEST' ||
                  service.status === 'PENDING_REQUEST'
              "
            >
              <RequestAccessButton
                :service="service"
                @open-request-access-modal="handleOpenModal"
              />
            </div>
            <div v-else>
              <div v-if="service.type === 'LINK'">
                <LinkButton
                  :service="service"
                  @open-confirmation-modal-link="handleOpenLinkModal"
                />
              </div>
              <div v-else-if="service.type === 'CODE'">
                <CodeButton
                  :service="service"
                  @open-confirmation-modal-code="handleOpenCodeModal"
                />
              </div>
              <div v-else>
                <FormButton
                  :service="service"
                  @open-confirmation-modal-form="handleOpenFormModal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
  <RequestAccessModal
    v-if="showRequestAccessModal"
    :service-id="selectedServiceIdForModal"
    @close-request-access-modal="showRequestAccessModal = false"
    @access-request-validated="refreshServiceData"
  />
  <CodeModal
    v-if="showCodeModal"
    :service-id="selectedServiceIdForModal"
    :service="selectedServiceForCodeModal"
    @request-code-validated="refreshServiceData"
    @close-code-modal="showCodeModal = false"
  />
  <LinkModal
    v-if="showLinkModal"
    :service="selectedServiceForLinkModal"
    @request-link-validated="refreshServiceData"
    @close-link-modal="showLinkModal = false"
  />
  <ProfExpressLoginModal
    v-if="showProfExpressLoginModal"
    :service="selectedServiceForLinkModal"
    @close-modal="showProfExpressLoginModal = false"
  />
  <FormModal
    v-if="showFormModal"
    :service="selectedServiceForm"
    @request-form-validated="refreshServiceData"
    @close-form-modal="showFormModal = false"
  />
</template>

<script>
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
/* eslint-enable import/no-unresolved */
import RequestAccessButton from './Services/RequestAccess/RequestAccessButton.vue';
import RequestAccessModal from './Services/RequestAccess/RequestAccessModal.vue';
import CodeButton from './Services/Code/CodeButton.vue';
import CodeModal from './Services/Code/CodeModal.vue';
import LinkButton from './Services/Link/LinkButton.vue';
import LinkModal from './Services/Link/LinkModal.vue';
import FormButton from './Services/Form/FormButton.vue';
import FormModal from './Services/Form/FormModal.vue';
import ProfExpressLoginModal from './Services/Link/ProfExpressLoginModal.vue';
import { useAuthStore } from '../store/store.js';
import { modifyServicesDescriptions } from '../utils/helpers.js';

export default {
  components: {
    ProfExpressLoginModal,
    Swiper,
    SwiperSlide,
    RequestAccessButton,
    RequestAccessModal,
    CodeButton,
    CodeModal,
    LinkButton,
    LinkModal,
    FormButton,
    FormModal,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
    selectedService: {
      type: Number,
      required: false,
    },
  },
  emits: ['require-service-refresh'],
  data() {
    return {
      apiBaseMedia: process.env.VUE_APP_BASE_MEDIA_URL,
      modules: [Pagination, Navigation],
      swiper: null,
      selectedServiceForLinkModal: null,
      selectedServiceIdForModal: null,
      selectedServiceForCodeModal: null,
      selectedServiceForm: [],
      showRequestAccessModal: false,
      showCodeModal: false,
      showFormModal: false,
      showLinkModal: false,
      showProfExpressLoginModal: false,
      expandedServiceId: null,
    };
  },
  computed: {
    user() {
      return useAuthStore().getUser;
    },
    shouldLoop() {
      return this.services.length > 2;
    },
    computedServices() {
      return modifyServicesDescriptions(this.services, this.apiBaseMedia);
    },
  },
  watch: {
    selectedService(newSelectedService) {
      this.handleSlideTo(newSelectedService);
    },
  },
  methods: {
    getType(service) {
      let type = 'media';
      if (
        service.media === ''
          || service.media === null
          || service.media === undefined
      ) {
        type = 'video';
      }
      return type;
    },
    getSource(service) {
      let url = '';
      if (service.video_1080) {
        url = `${this.apiBaseMedia}${service.video_1080}`;
      } else if (service.video_720) {
        url = `${this.apiBaseMedia}${service.video_720}`;
      } else if (service.video_480) {
        url = `${this.apiBaseMedia}${service.video_480}`;
      } else if (service.video_360) {
        url = `${this.apiBaseMedia}${service.video_360}`;
      }
      return url;
    },
    onSwiper(swiper) {
      this.swiper = swiper;
      swiper.on('slideChangeTransitionStart', () => {
        this.expandedServiceId = null;
      });
    },
    handleSlideTo(serviceId) {
      const slide = document.getElementById(`#service-${serviceId}`);
      const slideIndex = Array.from(this.swiper.slides)
        .findIndex((s) => s === slide);
      this.swiper.slideTo(slideIndex);
      const slideTop = slide.getBoundingClientRect().top + window.scrollY;
      const offset = window.innerHeight / 2 - slide.clientHeight / 2;
      const additionalOffset = 150;
      window.scrollTo({
        top: slideTop - offset - additionalOffset,
        behavior: 'smooth',
      });
    },
    refreshServiceData() {
      this.$emit('require-service-refresh');
    },
    handleOpenModal(serviceId) {
      this.selectedServiceIdForModal = serviceId;
      this.showRequestAccessModal = true;
    },
    handleOpenCodeModal(serviceId, service) {
      this.selectedServiceIdForModal = serviceId;
      this.selectedServiceForCodeModal = service;
      this.showCodeModal = true;
    },
    handleOpenLinkModal(service) {
      this.selectedServiceForLinkModal = service;
      if (this.user.company.use_external_api_profexpress && service.partner.id === 4 && !this.user.has_prof_express_account) {
        this.showProfExpressLoginModal = true;
      } else {
        this.showLinkModal = true;
      }
    },
    handleOpenFormModal(service) {
      this.selectedServiceForm = service;
      this.showFormModal = true;
    },
    toggleDetails(serviceId) {
      this.expandedServiceId = this.expandedServiceId === serviceId ? null : serviceId;
    },
  },
};
</script>
<style lang="scss">
.container-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 6rem;
}

.container-media {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
  height: 0rem;
}

.container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-bottom: 10px;
  color: #052f5f;
  font-size: 40px;
}

.text {
  color: #052f5f;
  max-width: 80%;
}

.container-content {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.logo-service-slider {
  height: 4rem;
  width: 100%;
}

.container-bottom {
  display: flex;
  width: 100%;
}

.container-media img,
.container-media video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.more-informations {
  cursor: pointer;
  color: #052f5f;
}

.more-informations-text {
  font-size: 13px;
  color: #052f5f;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 55rem;
  height: auto;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  color: #052f5f;
  font-family: "arrows" !important;
  content: "\e901" !important;
}

.swiper-button-next {
  &:after {
    content: "\e900" !important;
  }
}

.swiper-button-prev {
  left: 210px;
}

.swiper-button-next {
  right: 210px;
}

@media (min-width: 1650px) {
  .swiper-button-prev {
    left: calc(50% - 500px);
  }

  .swiper-button-next {
    right: calc(50% - 500px);
  }
}

@media (min-width: 1160px) and (max-width: 1300px) {
  .swiper-button-prev {
    left: 20px;
  }

  .swiper-button-next {
    right: 20px;
  }
}

@media (max-width: 1159px) {
  .swiper-slide {
    width: auto;
    max-width: 90%;
  }

  .swiper-button-prev {
    left: 0px;
  }

  .swiper-button-next {
    right: 0px;
  }
}

@media (max-width: 762px) {
  .container-header {
    flex-direction: column;
  }
  .container-bottom {
    flex-direction: column;
  }
}

.button-home-redirection {
  border: 2px solid #052f5f;
}
</style>
